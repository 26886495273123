.cookie-box {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #222;
  position: fixed;
  margin: 0;
  z-index: 1001;
  transition: all 0.5s ease;
  width: 100%;
  left: 0;
  bottom: 0;
  letter-spacing: 0; }
  .cookie-box, .cookie-box * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline; }
  .cookie-box.hidden {
    transform: translateY(100%); }
  .cookie-box__inner {
    padding: 15px; }
    @media (min-width: 420px) {
      .cookie-box__inner {
        display: flex;
        align-items: center;
        padding: 25px 15px; } }
  .cookie-box__content {
    flex: 1;
    display: flex;
    align-items: center; }
    .cookie-box__content__inner {
      flex: 1; }
  .cookie-box__icon {
    margin-right: 20px;
    width: 44px;
    position: relative; }
    .cookie-box__icon svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 44px;
      height: 44px;
      vertical-align: initial; }
  .cookie-box__title {
    font-weight: 700;
    color: currentColor;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 17px; }
  .cookie-box__desc {
    color: currentColor;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: left; }
    .cookie-box__desc a {
      color: currentColor;
      text-decoration: underline;
      white-space: nowrap; }
      .cookie-box__desc a:hover {
        color: currentColor;
        text-decoration: underline; }
  @media (min-width: 420px) {
    .cookie-box__buttons {
      padding-left: 15px; } }
  .cookie-box__button, .cookie-box__button:hover {
    font-weight: 700;
    display: block;
    border-radius: 24px;
    color: currentColor;
    cursor: pointer;
    font-size: 16px;
    background: transparent;
    border: 2px solid currentColor;
    padding: 10px 20px;
    white-space: nowrap;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: none;
    height: auto; }
  @media (max-width: 420px) {
    .cookie-box__button {
      margin: 15px auto 0; } }
  .cookie-box__button span {
    margin-right: 5px; }
  .cookie-box__button svg {
    position: relative;
    top: 3px; }
  .cookie-box__button span,
  .cookie-box__button svg {
    display: inline-block; }
